import React, { useEffect, useRef, useState } from 'react';
import { FleetManagementRow } from '../../fleet-management-row';
import TCell from '../../../../frontend-common-libs/src/components/common/tables/TCell';
import Checkbox from '../../../../frontend-common-libs/src/common/checkbox';
import styles from './styles/manage-reservations-row.module.scss';
import InstrumentReservation from '../../InstrumentReservation';
import { ImmutableMap, InstrumentItem } from '../../../../frontend-common-libs/src/common/types';
import ReservationFacade from '../../ReservationFacade';

export type Props = {
  instrument: ImmutableMap<InstrumentItem>;
  reservation: InstrumentReservation;
  selectAllCheckbox: boolean;
  updateSelectedInstruments: (id: string, select: boolean) => void;
  clearSelectedInstruments: boolean;
};

export default function ManageReservationsRow(props: Readonly<Props>) {
  const loadingProtocol = 'loading ...';

  const {
    instrument,
    selectAllCheckbox,
    updateSelectedInstruments,
    reservation,
    clearSelectedInstruments
  } = props;

  const [checkbox, setCheckbox] = useState<boolean>(selectAllCheckbox);
  const initialRender = useRef(true);
  const [protocolNameText, setProtocolNameText] = useState<string>(loadingProtocol);

  const instrumentId = instrument.get('id');
  const { desiredJobId, reportedJobId } = reservation;

  const reservationFacade = new ReservationFacade({
    instrument,
    reservation,
    userId: reservation.userId,
    reservationShadowLoaded: true
  });

  const updateProtocolNameText = async () => {
    const updatedText = await reservationFacade.getProtocolNameText();
    setProtocolNameText(updatedText);
  };

  useEffect(() => {
    updateProtocolNameText();
  }, [desiredJobId, reportedJobId]);

  const {
    instrumentFacade,
    displayedReservationStatus,
    reservationStatusProperties,
    canManageReservation
  } = reservationFacade;
  const { modelName } = instrumentFacade;

  const canCheckboxBeDisplayed = canManageReservation;

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (canCheckboxBeDisplayed) {
      setCheckbox(selectAllCheckbox);
      updateSelectedInstruments(instrumentId, selectAllCheckbox);
    }
  }, [selectAllCheckbox]);

  useEffect(() => {
    if (!canCheckboxBeDisplayed) {
      setCheckbox(false);
      updateSelectedInstruments(instrumentId, false);
    }
  }, [canCheckboxBeDisplayed]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (canCheckboxBeDisplayed) {
      if (clearSelectedInstruments && checkbox) {
        setCheckbox(false);
        updateSelectedInstruments(instrumentId, false);
      }
    }
  }, [clearSelectedInstruments]);

  const handleSelectInstrument = (): void => {
    setCheckbox(!checkbox);
    updateSelectedInstruments(instrumentId, !checkbox);
  };

  const renderCheckboxCell = () => (
    <TCell>
      {canCheckboxBeDisplayed ? (
        <Checkbox
          id={`checkbox_${instrumentId}`}
          name={`checkbox_${instrumentId}`}
          value={checkbox}
          checked={checkbox}
          onChange={handleSelectInstrument}
        />
      ) : null}
    </TCell>
  );

  const renderReservationStatus = (): React.JSX.Element => {
    return (
      <>
        <img
          src={reservationStatusProperties.src}
          alt={reservationStatusProperties.alt}
          className={styles.statusImg}
        />
        {displayedReservationStatus}
      </>
    );
  };

  const renderProtocolNameText = () => {
    return protocolNameText;
  };

  const renderAdditionalColumns = () => (
    <>
      <TCell
        className={`${styles.commonColumn} ${styles.cellText}`}
        title={displayedReservationStatus}
      >
        {renderReservationStatus()}
      </TCell>
      <TCell className={`${styles.commonColumn} ${styles.cellText}`} title={protocolNameText}>
        {renderProtocolNameText()}
      </TCell>
    </>
  );

  const tooltipMessage =
    'Cannot cancel the reservation or modify the assigned protocol. The instrument is either in use, offline, or in an error state.';

  return (
    <FleetManagementRow
      instrumentId={instrumentId}
      instrumentType={instrument.get('type')}
      modelName={modelName}
      instrumentName={instrument.get('name')}
      title={!canCheckboxBeDisplayed ? tooltipMessage : null}
    >
      {renderCheckboxCell()}
      {renderAdditionalColumns()}
    </FleetManagementRow>
  );
}

import InstrumentFacade, { BlockEnum, InstrumentStatusEnum } from '../common/InstrumentFacade';
import { ImmutableMap, InstrumentItem } from '../../frontend-common-libs/src/common/types';
import { ReservationState } from './types';
import reservedIcon from './assets/reserved-status.svg';
import availableIcon from './assets/available-status.svg';
import unavailableIcon from './assets/unavailable-status.svg';
import connectingIcon from '../assets/connecting-status.svg';
import { ReservationSectionsDesiredBlock } from './Reservation';
import InstrumentReservation from './InstrumentReservation';
import noneProtocolItem from './noneProtocolItem';

export const ReservationStatusEnum = {
  Reserved: 'Reserved',
  Available: 'Available',
  Unavailable: 'Unavailable',
  ReservationPending: 'Reservation Pending',
  CancellationPending: 'Cancellation Pending'
};

export type Props = {
  instrument: ImmutableMap<InstrumentItem>;
  reservation: InstrumentReservation;
  userId: string;
  reservationShadowLoaded: boolean;
  block?: BlockEnum;
};

export const RESERVATION_EXPIRATION_WINDOW = 5 * 60000;

export default class ReservationFacade {
  private readonly localInstrumentFacade: InstrumentFacade;

  private readonly reservation: InstrumentReservation;

  private readonly reservationState: ReservationState;

  private readonly userId: string;

  private readonly reservationShadowLoaded: boolean;

  public constructor(props: Props) {
    const { instrument, reservation, userId, reservationShadowLoaded, block } = props;
    this.reservation = reservation;
    this.reservationState = { reservationShadowState: reservation?.reservationShadowState };
    this.userId = userId;
    this.reservationShadowLoaded = reservationShadowLoaded;
    this.localInstrumentFacade = new InstrumentFacade(instrument, block);
  }

  public get isInstrumentReservedByUser(): boolean {
    return this.isReserved && this.userId === this.reservationState.reservationShadowState?.userId;
  }

  private get hasReservationShadow(): boolean {
    return this.reservationState.reservationShadowState != null;
  }

  private get isReserved(): boolean {
    return this.reservationState.reservationShadowState?.reserved ?? false;
  }

  private get isNotReserved(): boolean {
    return !this.isReserved;
  }

  private get isSomeoneLoggedIn(): boolean {
    return this.reservationState.reservationShadowState?.loggedIn ?? false;
  }

  public get noOneIsLoggedIn(): boolean {
    return !this.isSomeoneLoggedIn;
  }

  private get isIdle(): boolean {
    return this.instrumentFacade.instrumentStatus === InstrumentStatusEnum.Idle;
  }

  private get hasInstrumentSection(): boolean {
    return this.reservationState.reservationShadowState?.loggedIn !== null;
  }

  public get isInstrumentReservable(): boolean {
    return (
      this.hasReservationShadow &&
      this.hasInstrumentSection &&
      !this.isReservationPending &&
      !this.isCancellationPending &&
      this.isNotReserved &&
      this.noOneIsLoggedIn &&
      this.isIdle
    );
  }

  public get canManageReservation(): boolean {
    return this.isInstrumentReservedByUser && !this.isCancellationPending && this.isIdle;
  }

  private get desiredBlock(): ReservationSectionsDesiredBlock | undefined {
    return this.reservationState.reservationShadowState?.desired;
  }

  public get isReservationPending(): boolean {
    let someoneWantsToReserveIt = false;
    if (this.desiredBlock) {
      someoneWantsToReserveIt =
        !!this.desiredBlock.userId &&
        this.desiredBlock.userId !== '' &&
        this.desiredBlock.reserved === true;
    }
    return someoneWantsToReserveIt && this.isNotReserved;
  }

  public get isCancellationPending(): boolean {
    let someoneWantsToCancel = false;
    if (this.desiredBlock) {
      someoneWantsToCancel =
        this.desiredBlock.userId === '' && this.desiredBlock.reserved === false;
    }
    return this.isReserved && someoneWantsToCancel;
  }

  private currentUserWantsToReserveIt(): boolean {
    if (this.desiredBlock) {
      return this.desiredBlock.userId === this.userId;
    }
    return false;
  }

  public get displayedReservationStatus(): string {
    if (!this.isAllReservationShadowsLoaded) {
      return InstrumentStatusEnum.Connecting;
    }
    if (this.isReservationPending) {
      return this.currentUserWantsToReserveIt()
        ? ReservationStatusEnum.ReservationPending
        : ReservationStatusEnum.Unavailable;
    }
    if (this.isCancellationPending) {
      return this.isInstrumentReservedByUser
        ? ReservationStatusEnum.CancellationPending
        : ReservationStatusEnum.Unavailable;
    }
    if (this.isInstrumentReservedByUser) {
      return ReservationStatusEnum.Reserved;
    }
    return this.isInstrumentReservable
      ? ReservationStatusEnum.Available
      : ReservationStatusEnum.Unavailable;
  }

  public get instrumentFacade(): InstrumentFacade {
    return this.localInstrumentFacade;
  }

  public get isAllReservationShadowsLoaded(): boolean {
    return !this.instrumentFacade.isConnecting && this.reservationShadowLoaded;
  }

  public get reservationStatusProperties() {
    if (!this.isAllReservationShadowsLoaded) {
      return { src: connectingIcon, alt: 'connecting' };
    }
    if (this.isReservationPending) {
      return this.currentUserWantsToReserveIt()
        ? { src: reservedIcon, alt: 'reservation pending' }
        : { src: unavailableIcon, alt: 'unavailable' };
    }
    if (this.isCancellationPending) {
      return this.isInstrumentReservedByUser
        ? { src: reservedIcon, alt: 'cancellation pending' }
        : { src: unavailableIcon, alt: 'unavailable' };
    }
    if (this.isInstrumentReservedByUser) {
      return { src: reservedIcon, alt: 'reserved' };
    }
    return this.isInstrumentReservable
      ? { src: availableIcon, alt: 'available' }
      : { src: unavailableIcon, alt: 'unavailable' };
  }

  private reservationTimeout(): number {
    const reservationRequestTimestamp = this.desiredBlock?.metadata?.reserved ?? 0;
    const requestTimestampInMs = reservationRequestTimestamp * 1000;
    const timeout = RESERVATION_EXPIRATION_WINDOW - (Date.now() - requestTimestampInMs);
    if (timeout > 0) {
      return timeout;
    }
    return 0;
  }

  public reservationPendingTimeout(): number | null {
    if (this.isReservationPending) {
      return this.reservationTimeout();
    }
    return null;
  }

  public cancellationPendingTimeout(): number | null {
    if (this.isCancellationPending) {
      return this.reservationTimeout();
    }
    return null;
  }

  private get protocolCurrentlyAssigned(): boolean {
    return !!this.reservation.reportedJobId;
  }

  private get noPendingProtocolAssignment(): boolean {
    return this.reservation.desiredJobId === undefined;
  }

  private get pendingProtocolEqualsCurrentProtocol(): boolean {
    return this.reservation.desiredJobId === this.reservation.reportedJobId;
  }

  public get isBrioProtocolAssigned(): boolean {
    return (
      this.protocolCurrentlyAssigned &&
      (this.noPendingProtocolAssignment || this.pendingProtocolEqualsCurrentProtocol)
    );
  }

  public async getProtocolNameText(): Promise<string> {
    const noProtocolAssigned = noneProtocolItem.name;
    const { desiredJobId } = this.reservation;

    const pendingProtocolAssignment = !!desiredJobId;
    const pendingProtocolUnassignment = desiredJobId === null;

    if (pendingProtocolAssignment && !this.pendingProtocolEqualsCurrentProtocol) {
      const desiredProtocolName = await this.reservation.getDesiredProtocolName();
      return `Assigning ${desiredProtocolName}`;
    }
    if (this.protocolCurrentlyAssigned) {
      if (this.noPendingProtocolAssignment || this.pendingProtocolEqualsCurrentProtocol) {
        const reportedProtocolName = await this.reservation.getReportedProtocolName();
        return reportedProtocolName ?? noProtocolAssigned;
      }
      if (pendingProtocolUnassignment) {
        return 'Removing protocol assignment';
      }
    }
    return noProtocolAssigned;
  }
}

import React, { useState } from 'react';
import { Map } from 'immutable';
import styles from './styles/actions.module.scss';
import OpenLid from './assets/icons-instrument-tempo-open-lid.svg';
import CloseLid from './assets/icons-instrument-tempo-close-lid.svg';
import StartRun from './assets/icons-instrument-start-run.svg';
import StopRun from './assets/icons-instrument-stop-run.svg';
import SkipStep from './assets/icons-instrument-skip-step.svg';
import OpenLidGrayedOut from './assets/icons-instrument-tempo-open-lid-grayed-out.svg';
import CloseLidGrayedOut from './assets/icons-instrument-tempo-close-lid-grayed-out.svg';
import StartRunGrayedOut from './assets/icons-instrument-start-run-grayed-out.svg';
import StopRunGrayedOut from './assets/icons-instrument-stop-run-grayed-out.svg';
import SkipStepGrayedOut from './assets/icons-instrument-skip-step-grayed-out.svg';
import { VerticalIconButton } from '../../../../frontend-common-libs/src/components/common/buttons';
import { ImmutableMap, InstrumentItem } from '../../../../frontend-common-libs/src/common/types';
import InstrumentReservation from '../../InstrumentReservation';
import InstrumentFacade, {
  InstrumentLidStatusEnum,
  InstrumentStatusEnum
} from '../../../common/InstrumentFacade';
import SkipStepModal from '../../modals/SkipStepModal';
import StopRunModal from '../../modals/StopRunModal';
import StartRunModal from '../../modals/StartRunModal';
import ReservationFacade from '../../ReservationFacade';

export type Props = {
  instruments: ImmutableMap<InstrumentItem>[];
  reservations: Map<string, InstrumentReservation>;
  selectedInstruments: string[];
  clearSelection: () => void;
};

export default function Actions(props: Readonly<Props>) {
  const { instruments, reservations, selectedInstruments, clearSelection } = props;
  const numberOfSelectedInstruments = selectedInstruments.length;
  const [displayStopRunModal, setDisplayStopRunModal] = useState<boolean>(false);

  const [displaySkipStepModal, setDisplaySkipStepModal] = useState<boolean>(false);

  const [displayStartRunModal, setDisplayStartRunModal] = useState<boolean>(false);

  const onCloseSkipStepModal = () => {
    setDisplaySkipStepModal(false);
  };

  const onCloseStopRunModal = () => {
    setDisplayStopRunModal(false);
  };

  const onCloseStartRunModal = () => {
    setDisplayStartRunModal(false);
  };

  const getSelectedInstrumentsFacade = () => {
    return selectedInstruments
      .map(selectedInstrumentId => {
        const instrument = instruments.find(
          instrumentItem => instrumentItem.get('id') === selectedInstrumentId
        );
        if (instrument) {
          const reservation = reservations.get(selectedInstrumentId) as InstrumentReservation;
          return new ReservationFacade({
            instrument,
            reservation,
            userId: reservation.userId,
            reservationShadowLoaded: true
          });
        }
        return null;
      })
      .filter(item => item !== null);
  };

  const areInstrumentsAbleToSkipStep = (): boolean => {
    let validSelectedInstruments = selectedInstruments.length > 0;

    if (validSelectedInstruments) {
      validSelectedInstruments = getSelectedInstrumentsFacade().every(reservationFacade => {
        const { instrumentStatus, lidStatus } =
          reservationFacade?.instrumentFacade as InstrumentFacade;

        return (
          (instrumentStatus === InstrumentStatusEnum.Running ||
            instrumentStatus === InstrumentStatusEnum.InfiniteHold) &&
          lidStatus !== InstrumentLidStatusEnum.Opened &&
          reservationFacade?.isBrioProtocolAssigned
        );
      });
    }

    return validSelectedInstruments;
  };

  const areInstrumentsAbleToStartRun = (): boolean => {
    let validSelectedInstruments = selectedInstruments.length > 0;

    if (validSelectedInstruments) {
      validSelectedInstruments = getSelectedInstrumentsFacade().every(reservationFacade => {
        const { instrumentStatus, lidStatus } =
          reservationFacade?.instrumentFacade as InstrumentFacade;

        return (
          instrumentStatus === InstrumentStatusEnum.Idle &&
          lidStatus === InstrumentLidStatusEnum.Closed &&
          reservationFacade?.noOneIsLoggedIn &&
          reservationFacade?.isBrioProtocolAssigned
        );
      });
    }

    return validSelectedInstruments;
  };

  const areInstrumentsAbleToStopRun = (): boolean => {
    let validSelectedInstruments = selectedInstruments.length > 0;

    if (validSelectedInstruments) {
      validSelectedInstruments = getSelectedInstrumentsFacade().every(reservationFacade => {
        const { instrumentStatus } = reservationFacade?.instrumentFacade as InstrumentFacade;

        return (
          (instrumentStatus === InstrumentStatusEnum.Running ||
            instrumentStatus === InstrumentStatusEnum.InfiniteHold ||
            instrumentStatus === InstrumentStatusEnum.Paused) &&
          reservationFacade?.isBrioProtocolAssigned
        );
      });
    }

    return validSelectedInstruments;
  };

  const isRunningBrioProtocol = (reservationFacade: ReservationFacade): boolean => {
    const { instrumentFacade } = reservationFacade;
    return (
      (instrumentFacade.instrumentStatus === InstrumentStatusEnum.Running ||
        instrumentFacade.instrumentStatus === InstrumentStatusEnum.Paused ||
        instrumentFacade.instrumentStatus === InstrumentStatusEnum.InfiniteHold) &&
      reservationFacade.isBrioProtocolAssigned
    );
  };

  const areInstrumentsAbleToCloseLid = () => {
    return (
      getSelectedInstrumentsFacade().every(reservationFacade => {
        // @ts-ignore
        const { instrumentFacade } = reservationFacade;
        const isLidOpened = instrumentFacade?.lidStatus === InstrumentLidStatusEnum.Opened;

        return (
          isLidOpened &&
          instrumentFacade &&
          (instrumentFacade.instrumentStatus === InstrumentStatusEnum.Idle ||
            // @ts-ignore
            isRunningBrioProtocol(reservationFacade))
        );
      }) && selectedInstruments.length > 0
    );
  };

  const areInstrumentsAbleToOpenLid = () => {
    return (
      getSelectedInstrumentsFacade().every(reservationFacade => {
        // @ts-ignore
        const { instrumentFacade } = reservationFacade;
        const isLidClosed = instrumentFacade?.lidStatus === InstrumentLidStatusEnum.Closed;

        return (
          isLidClosed &&
          instrumentFacade &&
          (instrumentFacade.instrumentStatus === InstrumentStatusEnum.Idle ||
            // @ts-ignore
            isRunningBrioProtocol(reservationFacade))
        );
      }) && selectedInstruments.length > 0
    );
  };

  const startRunAllowed = areInstrumentsAbleToStartRun();
  const stopRunAllowed = areInstrumentsAbleToStopRun();
  const skipStepAllowed = areInstrumentsAbleToSkipStep();
  const closeLidAllowed = areInstrumentsAbleToCloseLid();
  const openLidAllowed = areInstrumentsAbleToOpenLid();

  const handleStartRun = async () => {
    setDisplayStartRunModal(false);
    const promises: Promise<void>[] = [];
    selectedInstruments.forEach(selectedInstrument => {
      const reservation = reservations.get(selectedInstrument) as InstrumentReservation;
      promises.push(reservation.startRun());
    });

    await Promise.all(promises);
  };

  const handleCloseLid = async () => {
    if (closeLidAllowed) {
      const closeLidPromises = selectedInstruments.map(selectedInstrument => {
        const reservation = reservations.get(selectedInstrument);
        return reservation?.instrumentCloseLid();
      });
      await Promise.all(closeLidPromises);
    }
  };

  const handleOpenLid = async () => {
    if (openLidAllowed) {
      const openLidPromises = selectedInstruments.map(selectedInstrument => {
        const reservation = reservations.get(selectedInstrument);
        return reservation?.instrumentOpenLid();
      });
      await Promise.all(openLidPromises);
    }
  };

  const handleStopRun = async () => {
    setDisplayStopRunModal(false);
    const promises: Promise<void>[] = [];
    selectedInstruments.forEach(selectedInstrument => {
      const reservation = reservations.get(selectedInstrument) as InstrumentReservation;
      promises.push(reservation.stopRun());
    });

    await Promise.all(promises);
  };

  const handleSkipStep = async () => {
    const promises: Promise<void>[] = [];
    selectedInstruments.forEach(selectedInstrument => {
      const reservation = reservations.get(selectedInstrument) as InstrumentReservation;
      promises.push(reservation.skipStep());
    });

    clearSelection();
    setDisplaySkipStepModal(false);

    await Promise.all(promises);
  };

  const handleDisplaySkipStepModal = () => {
    setDisplaySkipStepModal(true);
  };

  const getInstrumentNameList = () => {
    return getSelectedInstrumentsFacade().map(reservationFacade => {
      // @ts-ignore
      const { instrumentFacade } = reservationFacade;
      return `${instrumentFacade?.modelName} - ${instrumentFacade?.instrumentName}`;
    });
  };

  const handleStopRunModal = () => {
    setDisplayStopRunModal(true);
  };

  const handleStartRunModal = () => {
    setDisplayStartRunModal(true);
  };

  return (
    <section className={styles.actions}>
      <h1>Actions</h1>
      <div className={styles.counter} data-testid="counter">
        ({numberOfSelectedInstruments}) Selected Instruments
      </div>
      <div className={styles.lidButtons}>
        <VerticalIconButton
          id="open-lid-button"
          iconSrc={openLidAllowed ? OpenLid : OpenLidGrayedOut}
          alt="Open Lid"
          title={
            openLidAllowed
              ? 'Open Lid'
              : 'Cannot perform this operation on one or more of the selected instruments.'
          }
          className={`${styles.button} ${
            openLidAllowed ? styles.enabledButton : styles.grayedOutButton
          }`}
          onClick={handleOpenLid}
          disabled={!openLidAllowed}
        >
          Open Lid
        </VerticalIconButton>
        <VerticalIconButton
          id="close-lid-button"
          iconSrc={closeLidAllowed ? CloseLid : CloseLidGrayedOut}
          alt="Close Lid"
          title={
            closeLidAllowed
              ? 'Close Lid'
              : 'Cannot perform this operation on one or more of the selected instruments.'
          }
          className={`${styles.button} ${
            closeLidAllowed ? styles.enabledButton : styles.grayedOutButton
          }`}
          onClick={handleCloseLid}
          disabled={!closeLidAllowed}
        >
          Close Lid
        </VerticalIconButton>
      </div>
      <hr />
      <div className={styles.actionButtons}>
        <VerticalIconButton
          id="start-button"
          iconSrc={startRunAllowed ? StartRun : StartRunGrayedOut}
          alt="Start Run"
          title={
            startRunAllowed
              ? 'Start Run'
              : 'Cannot perform this operation on one or more of the selected instruments.'
          }
          className={`${styles.button} ${
            startRunAllowed ? styles.enabledButton : styles.grayedOutButton
          }`}
          onClick={handleStartRunModal}
          disabled={!startRunAllowed}
        >
          Start
        </VerticalIconButton>
        <VerticalIconButton
          id="skip-button"
          iconSrc={skipStepAllowed ? SkipStep : SkipStepGrayedOut}
          alt="Skip Step"
          title={
            skipStepAllowed
              ? 'Skip Step'
              : 'Cannot perform this operation on one or more of the selected instruments.'
          }
          className={`${styles.button} ${
            skipStepAllowed ? styles.enabledButton : styles.grayedOutButton
          }`}
          onClick={handleDisplaySkipStepModal}
          disabled={!skipStepAllowed}
        >
          Skip
        </VerticalIconButton>
        <VerticalIconButton
          id="stop-button"
          iconSrc={stopRunAllowed ? StopRun : StopRunGrayedOut}
          alt="Stop Run"
          title={
            stopRunAllowed
              ? 'Stop Run'
              : 'Cannot perform this operation on one or more of the selected instruments.'
          }
          className={`${styles.button} ${
            stopRunAllowed ? styles.stopButton : styles.grayedOutButton
          }`}
          onClick={handleStopRunModal}
          disabled={!stopRunAllowed}
        >
          Stop
        </VerticalIconButton>
      </div>

      <SkipStepModal
        handleSkipStep={handleSkipStep}
        onClose={onCloseSkipStepModal}
        show={displaySkipStepModal}
        skipStepAllowed={skipStepAllowed}
      />
      <StopRunModal
        instrumentNames={getInstrumentNameList()}
        handleStopRun={handleStopRun}
        onClose={onCloseStopRunModal}
        show={displayStopRunModal}
        stopRunAllowed={stopRunAllowed}
      />

      <StartRunModal
        handleStartRun={handleStartRun}
        onClose={onCloseStartRunModal}
        show={displayStartRunModal}
      />
    </section>
  );
}
